import { useState } from "react";
import headerStyles from "./header.module.css";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
//import vectorFlexGif from "https://imagedelivery.net/tOBsYzfwEvd5k1eoqpi24A/863ec5c5-f254-4113-a55e-522d7ab42700/public";
import vf_icon_crop from "../vector_art/vf_icon_crop.png";

export default function Header(props) {
  const navigate = useNavigate();
  const { menuOpen, handleOpenMenu, navToggle } = props;

  function handleClick(link) {
    console.log(link);
    const _link = link.toLowerCase();
    if (_link === undefined) {
      _link = "";
    }
    navigate(`/${_link}`);
  }

  return (
    <div className={headerStyles.headerContainer}>
      <div className={headerStyles.headerBox}>
        <div className={headerStyles.headerBoxLeft}>
          <div className={headerStyles.nextLinkLogoBox}>
            {!menuOpen ? (
              <button
                className={headerStyles.navButton}
                onClick={() => navigate("/")}
              >
                <div className={headerStyles.logoContainer}>
                  <img
                    src="https://imagedelivery.net/tOBsYzfwEvd5k1eoqpi24A/863ec5c5-f254-4113-a55e-522d7ab42700/public"
                    className={headerStyles.logoGif}
                  ></img>
                </div>
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className={headerStyles.linksRow}>
            <button
              className={headerStyles.linkButt}
              onClick={() => navigate("/aiml")}
            >
              AI/ML
            </button>
            <div>
              <button
                className={headerStyles.linkButt}
                onClick={() => navigate("/aiml")}
              >
                {" "}
                Web & Mobile Apps
              </button>
            </div>
            <div>
              <button
                className={headerStyles.linkButt}
                onClick={() => navigate("/projects")}
              >
                Artifacts
              </button>
            </div>

            <div>
              <button
                className={headerStyles.linkButt}
                onClick={() => navigate("/team")}
              >
                {" "}
                Team
              </button>
            </div>

            <div>
              <button
                className={headerStyles.linkButt}
                onClick={() => navigate("/founder")}
              >
                Founder
              </button>
            </div>
          </div>
        </div>
        <div className={headerStyles.headerBoxRight}>
          <div className={headerStyles.headerInnerLeft}>
            <img
              src="https://imagedelivery.net/tOBsYzfwEvd5k1eoqpi24A/5adf13ea-cb5d-4c3b-d05d-1cb55a193100/public"
              style={{ height: "65px" }}
            />
            <p className={headerStyles.logoText}>VECTOR FLEX</p>
          </div>
          <div className={headerStyles.headerHead}></div>
        </div>
      </div>
      <div className={headerStyles.burgerBox}>
        <button onClick={handleOpenMenu} className={headerStyles.burgerButton}>
          <div id="ks-menu_lines1" className={headerStyles.ksMenuLines}>
            {!menuOpen ? <div className={headerStyles.menuOpen}>⊟</div> : <></>}
          </div>
        </button>
      </div>
    </div>
  );
}

/*

 <Image src={logo} height={110} alt="sjDev logo" />
 */
