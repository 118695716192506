import { useEffect, useState, useCallback } from "react";
import styles from "../ai/temp.module.css";
import TextInput from "../../pageElements/TextInput.js";
import Subcontainer from "../../pageElements/Subcontainer.js";

export default function Apps() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [text, setText] = useState("");
  const [typewriterText, setTypewriterText] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [introRan, setIntroRan] = useState(false);

  const [responseText, setResponseText] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const welcome =
    "Would you like to learn how AI and Machine Leanring can make your business better?";
  /*
  const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_API_PROD;
 */

  const handleOnChange = useCallback((e) => {
    setInputValue(e.target.value);
  });

  function onSubmit(e) {
    setIsBusy(true);
    e.preventDefault();
    if (!inputValue || inputValue.length < 2) {
      return;
    } else {
      setInputValue("");
      setTypewriterText("");
      setText("");
      sendPrompt();
    }
  }

  useEffect(() => {
    if (introRan) {
      return;
    }
    setTimeout(setText, 50, welcome);
  }, []);

  useEffect(() => {
    setTimeout(setIntroRan, 55, true);
  }, []);

  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  async function sendPrompt(promptVaslue) {
    const promptText = JSON.stringify({ prompt: inputValue });
    const response = await fetch(`${apiUrl}/v1/lm-app-info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: promptText,
    });
    const data = await response.json();
    console.log("data received in send prompt", data);
    setText(data);
  }

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      setTypewriterText(
        (prevTypewriterText) => prevTypewriterText + text.charAt(index)
      );
      index++;
      if (index === text.length) {
        clearInterval(timer);
      }
    }, 30); // adjust timing here

    return () => clearInterval(timer); // cleanup on unmount
  }, [text]);

  useEffect(() => {
    console.log("responseText useEffect fired");
    let index = 0;
    const timer = setInterval(() => {
      setAnswerText(
        (prevAnswerText) => prevAnswerText + responseText.charAt(index)
      );
      index++;
      if (index === responseText.length) {
        clearInterval(timer);
      }
    }, 30); // adjust timing here

    return () => clearInterval(timer); // cleanup on unmount
  }, [responseText]);

  return (
    <div>
      APP PAGE
      <Subcontainer
        onSubmit={onSubmit}
        typewriterText={typewriterText}
        inputValue={inputValue}
        onChange={handleOnChange}
        introRan={introRan}
        isBusy={isBusy}
      />
    </div>
  );
}
