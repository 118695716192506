import "./App.css";
import "./index.scss";
import { useEffect } from "react";
import Header from "./pageElements/Header.js";
import React, { useContext, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import SplashPage from "./pages/splash/Splashpage.js";
import Aiml from "./pages/ai/AIML.js";
import Apps from "./pages/apps/Apps.js";
import Founder from "./pages/founder/Founder.js";
import Team from "./pages/team/Team.js";
import Footer from "./pageElements/footer.js";
import Navpanel from "./pages/navpanel/navpanel.js";
import FounderRedirect from "./pages/team/founder/FounderRedir.js";
import Projects from "./pages/projects/Projects.js";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [introDone, setIntroDone] = useState(false);
  const [introRanResBot, setIntroRanResBot] = useState(false);
  function navToggle() {
    setMenuOpen(!menuOpen);
  }

  const handleOpenMenu = () => {
    const temp = !menuOpen;
    return setMenuOpen(temp);
  };

  return (
    <React.Fragment>
      <Header
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        handleOpenMenu={handleOpenMenu}
        navToggle={navToggle}
      />
      <Navpanel
        navToggle={navToggle}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Routes>
        <Route exact path="/" element={<SplashPage />} />
        <Route
          exact
          path="/aiml"
          element={<Aiml introDone={introDone} setIntroDone={setIntroDone} />}
        />
        <Route
          path="/apps"
          element={<Apps setIntroDone={setIntroDone} introDone={introDone} />}
        />
        <Route path="/projects" element={<Projects />} />
        <Route path="/team" element={<Team />} />
        <Route
          path="/founder"
          element={
            <Founder
              introRanResBot={introRanResBot}
              setIntroRanResBot={setIntroRanResBot}
            />
          }
        />
        <Route path="/team/founder" element={<FounderRedirect />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
