import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./splashpage.module.css";
import Subcontainer from "../../pageElements/Subcontainer.js";
import TextInput from "../../pageElements/TextInput.js";

const Splashpage = (props) => {
  const navigate = useNavigate();

  const [introRan, setIntroRan] = useState(false);

  function navHome() {
    navigate("/aiml");
  }

  useEffect(() => {
    setTimeout(setIntroRan, 7000, true);
  }, [introRan]);

  useEffect(() => {
    setTimeout(navHome, 7005);
  }, [introRan]);

  return (
    <div className={styles.homeMainContainer}>
      <div className={introRan ? styles.homeInage2 : styles.homeImage}>
        {introRan ? <div className={styles.elementContainer}></div> : <></>}
      </div>
    </div>
  );
};

export default Splashpage;

/*
            <div className={styles.formContainer}>
              <form className={styles.inputForm} onSubmit={onSubmit}>
                <TextInput
                  name="prompt"
                  value={inputValue}
                  onChange={handleOnChange}
                  id="target-element"
                />
              </form>
            </div>


              useEffect(() => {
    console.log("responseText useEffect fired");
    let index = 0;
    const timer = setInterval(() => {
      setAnswerText(
        (prevAnswerText) => prevAnswerText + responseText.charAt(index)
      );
      index++;
      if (index === responseText.length) {
        clearInterval(timer);
      }
    }, 30); // adjust timing here

    return () => clearInterval(timer); // cleanup on unmount
  }, [responseText]);
         */
