import React from "react";
import styles from "./page.module.css";
import { useEffect, useState, useCallback } from "react";
import TextInput from "./TextInput";
import foundStyles from "./Founder.module.css";
import { PushSpinner } from "react-spinners-kit";
import { DominoSpinner } from "react-spinners-kit";

const Subcontainer = (props) => {
  const { introRan, typewriterText, onSubmit, value, onChange, isBusy } = props;
  console.log("value in subcontainer", value);
  return (
    <div className={styles.subContainer}>
      <div
        style={{
          fontSize: "1.1rem",
          letterSpacing: ".3rem",
          textAlign: "left",
          lineHeight: "1.32",
          height: "495px",
          minWidth: "900px",
          border: "1px solid rgb(255, 90, 230)",
          borderRadius: "15px 15px 120px 0px",
        }}
      >
        <div className={styles.lmTextContainer}>
          {isBusy ? (
            <></>
          ) : (
            <div className={styles.scrollText}>
              {typewriterText?.split("\n").map((t, key) => {
                return (
                  <p key={key} className={foundStyles.botGraph}>
                    {t}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className={styles.textBus}>
        <form className={styles.inputForm} onSubmit={onSubmit}>
          <TextInput value={value} onChange={onChange} />
        </form>
      </div>
    </div>
  );
};

export default Subcontainer;
