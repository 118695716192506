import React from "react";
import styles from "./page.module.css";
import foundStyles from "./Founder.module.css";
import TextInput from "./TextInput";
import sjPic from "..//vector_art/sj_loop.jpg";
import { PushSpinner } from "react-spinners-kit";

const TempSub = (props) => {
  const { introRan, typewriterText, onSubmit, inputValue, onChange, isBusy } =
    props;

  const chatWindow = document.getElementById("baz");
  if (chatWindow) chatWindow.scrollTop = chatWindow.scrollHeight;

  return (
    <div className={foundStyles.subContainer}>
      <div className={foundStyles.midContainer}>
        <div className={foundStyles.tempDialogBox}>
          <div className={foundStyles.foundScrollText}>
            {isBusy ? (
              <div className={foundStyles.spinBox}>
                <PushSpinner size={55} color={"rgb(255, 90, 230)"} />
              </div>
            ) : (
              <div className={foundStyles.baz} id="baz">
                {typewriterText?.split("\n").map((t, key) => {
                  return (
                    <p key={key} className={foundStyles.botGraph}>
                      {t}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={foundStyles.formContainer}>
        <form className={styles.inputForm} onSubmit={onSubmit}>
          <TextInput
            name="prompt"
            value={inputValue}
            onChange={onChange}
            id="target-element"
          />
        </form>
      </div>
    </div>
  );
};

export default TempSub;
