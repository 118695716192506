import React from "react";
import styles from "./textInput.module.css";
const TextInput = (props) => {
  const {
    error,
    onSubmit,
    message,
    label,
    name,
    value,
    onChange,
    id,
    type,
    disabled,
  } = props;
  console.log("value in text input", value);

  return (
    <div className={styles.inputContainer}>
      {label ? (
        <label htmlFor="signup" className="form-label">
          {label}
        </label>
      ) : (
        <></>
      )}
      <input
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        onChange={onChange}
        type={type}
        style={{
          height: "80%",
          width: "90%",
          padding: ".5rem",
          fontSize: "1.5rem",
          border: "none",
          backgroundColor: "#000",
          color: "#fff",
        }}
      />
      {error ? <div className="textinput-error-box">{message}</div> : <></>}
    </div>
  );
};

export default TextInput;
