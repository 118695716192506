import { useEffect, useState, useCallback } from "react";
import styles from "./temp.module.css";
import homeStyles from "../../pageElements/Home.module.css";
import TempSub from "../../pageElements/tempSub.js";

const Aiml = (props) => {
  const { introDone, setIntroDone } = props;
  const [typewriterText, setTypewriterText] = useState("");
  const [text, setText] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [introRan, setIntroRan] = useState(false);
  const welcome =
    '  W elcome to Vector Flex.  We empower organizations to thrive by leveraging artificial intelligence and machine learning in tandem with traditional web technologies.\n Our innovations improve efficiency, and elevate customer experiences.  \n I’m Peitho, here to guide you in learning how your enterprise can run better and increase profitability, all while you gain the freedom to *plan*, instead of merely "manage" \n Let us transform your business - ask me anything, or just enter "tell me more."';

  const handleOnChange = useCallback((e) => {
    setInputValue(e.target.value);
  });

  function initText(msg) {
    setText(msg);
    setIntroDone(true);
  }

  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  useEffect(() => {
    if (introDone) {
      return;
    } else {
      initText(welcome);
    }
  }, []);

  useEffect(() => {
    if (!text || text.length < 1) {
      return;
    }

    let index = 0;
    const timer = setInterval(() => {
      setTypewriterText(
        (prevTypewriterText) => prevTypewriterText + text?.charAt(index)
      );
      index++;
      if (index === text.length) {
        clearInterval(timer);
      }
    }, 25); // adjust timing here

    return () => clearInterval(timer); // cleanup on unmount
  }, [text]);

  function onSubmit(e) {
    e.preventDefault();
    setIsBusy(true);
    if (!inputValue || inputValue.length < 1) {
      return;
    } else {
      const promptValue = inputValue;
      setInputValue("");
      setText(" ");
      setTypewriterText(" ");
      sendPrompt(promptValue);
    }
  }

  async function sendPrompt(promptValue) {
    console.log("send prompt fired prompt received", promptValue);
    let promptText = "";
    if (promptValue.toLowerCase() == "tell me more") {
      promptText = JSON.stringify({
        prompt: "tell me more about how machine learning can help my business",
      });
    } else {
      promptText = JSON.stringify({ prompt: promptValue });
      console.log("promptText", promptText);
    }
    try {
      console.log("*************************", promptText);
      const response = await fetch(`${apiUrl}/v1/lm-app-info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: promptText,
      });
      const data = await response.json();
      setText(` ${data}`);
      setIsBusy(false);
    } catch (e) {
      console.log("e", e);
      setText("Sorry, I didn't understand that, try again.");
    }
  }

  return (
    <div className={homeStyles.container}>
      <div className={styles.foundSubContainer}>
        <TempSub
          onSubmit={onSubmit}
          typewriterText={typewriterText}
          inputValue={inputValue}
          onChange={handleOnChange}
          isBusy={isBusy}
        />
      </div>
    </div>
  );
};

export default Aiml;
