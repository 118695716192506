import { useNavigate } from "react-router-dom";

import styles from "./footer.module.css";

const Footer = () => {
  const navigate = useNavigate();

  function handleClick(route) {
    //navigate(`/${route}`);
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerUpperBox}>
        <div className={styles.footerThird1}>
          <a className={styles.footerItemLink} onClick={() => handleClick("")}>
            Terms of Service
          </a>
        </div>
        <div className={styles.footerThird2}>
          <a className={styles.footerItemLink} onClick={() => handleClick("")}>
            Accessibility
          </a>
        </div>
        <div className={styles.footerThird3}>
          <a className={styles.footerItemLink} onClick={() => handleClick("")}>
            Privacy
          </a>
        </div>
      </div>
      <div className={styles.footerLowerBox}>
        <p className={styles.footerItem}>
          © 2023-2024 Vector Flex - All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;

/*
        <img className="footer-logo" src={}></img>

        */
