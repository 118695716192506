import React from "react";
import styles from "./page.module.css";
import foundStyles from "./Founder.module.css";
import TextInput from "./TextInput";
import sjPic from "..//vector_art/sj_loop.jpg";
import { PushSpinner } from "react-spinners-kit";

const FoundSub = (props) => {
  const { introRan, typewriterText, onSubmit, isBusy, inputValue, onChange } =
    props;

  const chatWindow = document.getElementById("baz");
  if (chatWindow) chatWindow.scrollTop = chatWindow.scrollHeight;

  return (
    <div className={foundStyles.subContainer}>
      <div
        style={{
          fontSize: "1.1rem",
          letterSpacing: ".3rem",
          textAlign: "left",
          lineHeight: "1.32",
          height: "540px",
        }}
      >
        <div className={foundStyles.mobileFounderImageBox}>
          <div className={foundStyles.mobileImageContainer}>
            <div className={foundStyles.mobileImageContainer2}>
              <img
                style={{
                  borderRadius: "22px 0px",
                  margunRight: "5px",
                  height: "170px",
                }}
                src={sjPic}
              />
            </div>
          </div>
        </div>
        <div className={foundStyles.upperDialogBox}>
          <div className={foundStyles.leftTextBox}>
            <div className={foundStyles.foundScrollText}>
              {isBusy ? (
                <div className={foundStyles.spinBox}>
                  <PushSpinner size={55} color={"rgb(255, 90, 230)"} />
                </div>
              ) : (
                <div className={foundStyles.baz} id="baz">
                  {typewriterText?.split("\n").map((t, key) => {
                    return (
                      <p key={key} className={foundStyles.botGraph}>
                        {t}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className={foundStyles.rightTextBox}>
            <div className={foundStyles.scrollText}>
              <div className={foundStyles.imageContainer}>
                <div className={foundStyles.imageContainer2}>
                  <img
                    style={{
                      borderRadius: "22px 0px",
                      margunRight: "5px",
                      height: "170px",
                    }}
                    src="https://imagedelivery.net/tOBsYzfwEvd5k1eoqpi24A/01768843-6603-41a7-39fd-7629f72e4d00/public"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={foundStyles.formContainer}>
        <form className={styles.inputForm} onSubmit={onSubmit}>
          <TextInput
            name="prompt"
            value={inputValue}
            onChange={onChange}
            id="target-element"
          />
        </form>
      </div>
    </div>
  );
};

export default FoundSub;
