import React from "react";
import navStyles from "./Navpanel.module.css";
import contactStyles from "./Contact.module.css";
import { Link } from "react-router-dom";

const Navpanel = (props) => {
  const { menuOpen, setMenuOpen, navToggle } = props;

  const mobileMainHeadingClass = menuOpen
    ? navStyles.mobileMainHeadingShow
    : navStyles.mobileMainHeadingHide;

  const mobileHeadingWrapperClass = menuOpen
    ? navStyles.mobileHeadingWrapperShow
    : navStyles.mobileHeadingWrapperHide;

  return (
    <div className={mobileMainHeadingClass}>
      <div className={mobileHeadingWrapperClass}>
        <div className={navStyles.mobileHeadingClose}>
          <div className={navStyles.mobileHeadingCloseInner}>
            <a className={navStyles.mobileHeadingButton} onClick={navToggle}>
              ⊟
            </a>
          </div>
        </div>
        <div className={navStyles.mobileHeadingLinkWrap}>
          <div className={navStyles.mobileInfoBox}>
            <div className={contactStyles.contactDetailOne}>
              <a className={contactStyles.contactLink} href="/aiml">
                ai/ml
              </a>
            </div>
            <div className={contactStyles.contactDetailTwo}>
              <a className={contactStyles.contactLinkTwo} href="/projects">
                artifacts
              </a>
            </div>
            <div className={contactStyles.contactDetailThree}>
              <a className={contactStyles.navLinkThree} href="/founder">
                founder
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navpanel;
