import { useState } from "react";
import homeStyles from "./Home.module.css";
import featuredStyles from "./Featured.module.css";

import LaunchIcon from "@mui/icons-material/Launch";

//import Navpanel from "../pageElements/navpanel.js";

const Projects = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  function navToggle() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div className={homeStyles.container}>
      <div className={homeStyles.innerContainer}>
        {/*
        <Navpanel
          navToggle={navToggle}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        */}
        <div className={featuredStyles.featuredGrid}>
          {/****    NOVODRAFT START    *****/}
          <div className={homeStyles.cardImageRight}>
            <div className={featuredStyles.gitBoxRight}>Visit Site</div>
            <a href="" target="_blank" style={{ marginTop: "6px" }}>
              <LaunchIcon fontSize="large" />
            </a>
          </div>
          <a
            className={homeStyles.cardTextLeft}
            href="https://www.novodraft.ai"
            target="_blank"
          >
            <h3>Novdraft.ai </h3>
            <p>
              AI-assisted legal drafting. ML-augmented OCR plus CoT-prompt
              sequencing generates contextually-precise, persuasive content.
            </p>
          </a>

          {/****    EXF END    *****/}
          {/****    SF START    *****/}
          <a
            href="http://www.findandexec.com/sf"
            className={homeStyles.cardTextRight}
          >
            <h3>Sales Flow</h3>
            <p>
              Node/React app demo, created for an automotive retailer, with ML
              processing of KPI and inventory data.
            </p>
          </a>
          <div className={homeStyles.cardImageLeft}>
            <a
              href="https://github.com/kjannette/salesflow"
              target="_blank"
              style={{ marginTop: "6px" }}
            >
              {" "}
              <LaunchIcon fontSize="large" />{" "}
            </a>
            <div className={featuredStyles.gitBox}>View on Github</div>
          </div>
          {/****    SF END    *****/}
          {/****    BB START    *****/}
          <div className={homeStyles.cardImageRight}>
            <div className={featuredStyles.gitBoxRight}>View on Github</div>
            <a href="" target="_blank" style={{ marginTop: "6px" }}>
              {" "}
              <LaunchIcon fontSize="large" />{" "}
            </a>
          </div>
          <a
            href="http://www.findandexec.com/bb"
            target="_blank"
            className={homeStyles.cardTextLeft}
          >
            <h3>Book Browser</h3>
            <p>
              Node/React app. Use-case was a client in the rare, collectible and
              new book retail space, with a youthful user base.
            </p>
          </a>
          {/****    BB END    *****/}
          {/****    BT START    *****/}
          <a href="" className={homeStyles.cardTextRight}>
            <h3>Budgetize</h3>
            <p>
              JS app for member dashboard of a business assoc. site. Snippet
              demos the 'monthly' budget view component.
            </p>
          </a>
          <div className={homeStyles.cardImageLeft}>
            <a href="" target="_blank" style={{ marginTop: "6px" }}>
              {" "}
              <LaunchIcon
                style={{ fill: "rgb(255, 90, 230)" }}
                fontSize="large"
              />
            </a>
            <div className={featuredStyles.gitBox}>View on Github</div>
          </div>
          {/****    BT END    *****/}
        </div>

        <style jsx>{`
          main {
            padding: 5rem 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          footer {
            width: 100%;
            height: 100px;
            border-top: 1px solid #eaeaea;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          footer img {
            margin-left: 0.5rem;
          }
          footer a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: inherit;
          }
          code {
            background: #fafafa;
            border-radius: 5px;
            padding: 0.75rem;
            font-size: 1.1rem;
            font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
              DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
          }
        `}</style>

        <style jsx global>{`
          html,
          body {
            height: 100vh;
            padding: 0;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
            background-color: #fff;
          }
          * {
            box-sizing: border-box;
          }
        `}</style>
      </div>
    </div>
  );
};

export default Projects;
