import { useEffect, useState, useCallback } from "react";
import styles from "./founder.module.css";
import homeStyles from "../../pageElements/Home.module.css";
import FoundSub from "../../pageElements/foundSub.js";

const Founder = (props) => {
  const { introRanResBot, setIntroRanResBot } = props;
  const [typewriterText, setTypewriterText] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [text, setText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleOnChange = useCallback((e) => {
    setInputValue(e.target.value);
  });
  const welcome =
    "G reetings.  I'm Pietho, AI assistant to Steven Jannette, technologist and advanced full-stack engineer.  \n In the past decade, Steven has launched and scaled successful enterprise applications for organizations big and small.  \n From Fortune-10 giant Ford Motor Company, to nimble startups, Steven’s expertise and experience in AI applications have yielded impressive results.   \n One company scored its first two-consecutive > $100 million sales years using an app suite he created.  \n Another made FastCompany magazine's “World’s Most Innovative” list – alongside venerable Apple. \n Ask me anything about Steven's career, I'm an expert.";

  function initText(msg) {
    setText(msg);
    setIntroRanResBot(true);
  }

  useEffect(() => {
    if (introRanResBot) {
      return;
    } else {
      initText(welcome);
    }
  }, []);

  useEffect(() => {
    if (!text || text.length < 0) {
      return;
    }
    let index = 0;
    const timer = setInterval(() => {
      setTypewriterText(
        (prevTypewriterText) => prevTypewriterText + text?.charAt(index)
      );
      index++;
      if (index === text.length) {
        clearInterval(timer);
      }
    }, 30); // adjust timing here

    return () => clearInterval(timer); // cleanup on unmount
  }, [text]);

  function onSubmit(e) {
    e.preventDefault();
    if (!inputValue || inputValue.length < 2) {
      return;
    } else {
      setIsBusy(true);
      const promptValue = inputValue;
      setInputValue("");
      setText(" ");
      setTypewriterText(" ");
      sendPrompt(promptValue);
    }
  }

  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_DEV
      : process.env.REACT_APP_API_PROD;

  async function sendPrompt(promptValue) {
    console.log("send prompt fired");
    const promptText = JSON.stringify({ prompt: promptValue });
    try {
      const response = await fetch(`${apiUrl}/v1/lm-cr-query`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        json: true,
        body: promptText,
      });
      const data = await response.json();
      if (response.status === 200) {
        setText(data);
        setIsBusy(false);
      } else {
        console.log(`server returned err: ${response.status}`);
      }
    } catch (e) {
      console.log("http error in sP rqst");
    }
  }

  function navToggle() {
    //pass
  }

  return (
    <div className={homeStyles.container}>
      <div className={styles.foundSubContainer}>
        <FoundSub
          onSubmit={onSubmit}
          typewriterText={typewriterText}
          inputValue={inputValue}
          onChange={handleOnChange}
          isBusy={isBusy}
        />
      </div>
    </div>
  );
};

export default Founder;
